'use strict';

function syncArticleHeights() {
    $('.article-tiles .tile-figure').matchHeight();
    $('.article-tiles .tile-top').matchHeight();
    $('.article-tiles .tile-description').matchHeight();
}

module.exports = {
    pdp: function () {
        var articleData = $('#resource-article-data').text() ? JSON.parse($('#resource-article-data').text()) : null;
        var $articlesContainer = $('.resource-articles');
        var $articleTemplate = $('.resource-articles .article.template');

        if (articleData) {
            $.each(articleData, function (key) {
                $articleTemplate.clone().appendTo($articlesContainer).removeClass('template').addClass('tile-' + articleData[key].id);
                var $thisTile = $('.tile-' + articleData[key].id);
                if (articleData[key].custom.articleImage) {
                    $thisTile.find('.tile-image').attr('data-src', articleData[key].custom.articleImage);
                }
                if (articleData[key].custom.articleImageAlt) {
                    $thisTile.find('.tile-image').attr('alt', articleData[key].custom.articleImageAlt);
                }
                if (articleData[key].custom.articleTitle) {
                    $thisTile.find('.tile-title .tile-title-inner').text(articleData[key].custom.articleTitle);
                }
                if (articleData[key].custom.articleDesc) {
                    $thisTile.find('.tile-description .tile-description-inner').text(articleData[key].custom.articleDesc);
                }
                if (articleData[key].custom.articleURL) {
                    $thisTile.find('.button-component .btn').attr('href', articleData[key].custom.articleURL);
                }
            });
        }
    },
    pdSingle: function () {
        var $pdArticles = $('.pd.resource-article-data');

        if ($pdArticles.length) {
            $pdArticles.each(function (i) {
                try {
                    var articleData = JSON.parse($(this).text());
                } catch (e) {
                    return false;
                }

                var $articleTile = $(this).siblings('.article');

                $articleTile.removeClass('template col-4 col-3 col').addClass('tile-' + articleData.id);
                if (articleData.custom.articleImage) {
                    $articleTile.find('.tile-image').attr('data-src', articleData.custom.articleImage);
                }
                if (articleData.custom.articleImageAlt) {
                    $articleTile.find('.tile-image').attr('alt', articleData.custom.articleImageAlt);
                }
                if (articleData.custom.articleTitle) {
                    $articleTile.find('.tile-title .tile-title-inner').text(articleData.custom.articleTitle);
                }
                if (articleData.custom.articleDesc) {
                    $articleTile.find('.tile-description .tile-description-inner').text(articleData.custom.articleDesc);
                }
                if (articleData.custom.articleURL) {
                    $articleTile.find('.article-url').attr('href', articleData.custom.articleURL);
                }
            });

            syncArticleHeights();

            // hide all tiles beyond 6 in each grouping
            $('.article-tile-block:not(.editing) .article-tile-outer:nth-child(n+7)').addClass('js-hidden');

            $('.view-all-articles').on('click', function () {
                $(this).parents('.article-tile-block').find('.article-tile-outer').removeClass('js-hidden');
                $(this).parents('.article-tile-summary').remove();
            });

            $(window).smartresize(function () {
                syncArticleHeights();
            });

            $(document).on('lazyloaded', function (e) {
                syncArticleHeights();
            });
        }
    },
    compTileBlock: function () {
        var $articleTileBlock = $('.experience-commerce_layouts-articleTileBlock .article-tile-block');
        var maxItems = parseInt($articleTileBlock.attr('data-max-items')) + 1;

        if ($articleTileBlock.length) {
            syncArticleHeights();

            // hide all tiles beyond 6 in each grouping
            $('.article-tile-block:not(.editing) .article-tile-outer:nth-child(n+' + maxItems + ')').addClass('js-hidden');
            $('.view-all-articles').on('click', function () {
                $(this).parents('.article-tile-block').find('.article-tile-outer').removeClass('js-hidden');
                $(this).parents('.article-tile-summary').remove();
            });

            $(window).smartresize(function () {
                syncArticleHeights();
            });

            $(document).on('lazyloaded', function (e) {
                syncArticleHeights();
            });
        }
    }
};
