'use strict';

var Video = (function () {
    // VARIABLES
    var players = []; // players array (to control players individually)
    var queue = []; // videos queue (once api is ready, transform this into YT player)

    // Constructor
    function videos() {}

    // METHODS
    // Add elements to queue
    videos.prototype.add = function ($video) {
        queue.push($video);
    };

    // Load YT API
    videos.prototype.loadApi = function () {
        var tag = document.createElement('script');

        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = function () {
            queue.forEach(function ($video) {
                // Create the YT player
                if ($video.attr('data-type') === 'in-modal') {
                    var player = new YT.Player($video.get(0), {
                        width: '100%',
                        height: '100%',
                        videoId: $video.data('id'),
                        playerVars: {
                            autoplay: 0,
                            mute: 0,
                            controls: 1,
                            modestbranding: 0,
                            playsinline: 1,
                            rel: 0,
                            loop: 0
                        },
                        events: {
                            onReady: onModalPlayerReady
                        }
                    });

                    // add to players array
                    players.push(player);
                } else if ($video.attr('data-type') === 'in-page') {
                    // Create the YT player
                    var player = new YT.Player($video.get(0), {
                        width: '100%',
                        height: '100%',
                        videoId: $video.data('id'),
                        playerVars: {
                            autoplay: 0,
                            mute: 0,
                            controls: 1,
                            modestbranding: 0,
                            playsinline: 1,
                            rel: 0,
                            loop: 0
                        },
                        events: {
                            onReady: onPlayerReady
                        }
                    });
                    // add to players array
                    players.push(player);
                } else if ($video.attr('data-type') === 'vid-bg') {
                    var player = new YT.Player($video.get(0), {
                        width: '100%',
                        height: '100%',
                        videoId: $video.data('id'),
                        playerVars: {
                            // autoplay: 1,
                            mute: 1,
                            controls: 0,
                            modestbranding: 1,
                            playsinline: 1,
                            rel: 0,
                            loop: 1,
                            playlist: $video.data('id')
                        },
                        events: {
                            onReady: onBgVidPlayerReady,
                            onStateChange: onPlayerStateChange
                        }
                    });
                    // add to players array
                    players.push(player);
                }
            });
        };

        /**
         * Set events on player ready
         * @param {Object} event - contains event data
         */
        function onModalPlayerReady(event) {
            var $iframe = $(event.target.getIframe());
            var $modal = $iframe.parents('.modal');
            var componentID = $modal.attr('data-modalcomponentid');
            var $pairedComponent = $('.pd-video-component[data-componentid=' + componentID + ']');
            var $vidPoster = $pairedComponent.find('.poster video');

            $modal.on('show.bs.modal', function () {
                event.target.playVideo();

                if($vidPoster) {
                    $vidPoster.get(0).pause();
                    // $vidPoster.get(0).currentTime = 0;
                }
            });

            $modal.on('hide.bs.modal', function () {
                event.target.stopVideo();

                if($vidPoster) {
                    $vidPoster.get(0).play();
                }

            });
        }

        /**
         * Set events on player ready
         * @param {Object} event - contains event data
         */
        function onPlayerReady(event) {
            var $iframe = $(event.target.getIframe());
            var $figure = $iframe.parents('.main-figure');
            var $poster = $figure.find('.poster');
            var $heading = $figure.find('.image-heading-container');
            var $vidfigure = $figure.find('.video-figure');

            if (window.matchMedia('(min-width: 48em)').matches) {
                $poster.add($heading).on('click', function () {
                    $poster.addClass('js-hidden');
                    $heading.addClass('js-hidden');
                    $vidfigure.show();
                    event.target.playVideo();
                });
            } else {
                var compID = $iframe.parents('.pd-video-component').attr('data-componentid');
                var modalID = 'modal-' + compID;
                var $modal = $('#' + modalID);

                $poster.add($heading).on('click', function () {
                    $modal.modal('show');
                });
            }
        }

        /**
         * Set events on player ready
         * @param {Object} event - contains event data
         */
        function onBgVidPlayerReady(event) {
            // play video if it's on screen on page load
            var scrollPos = $(window).scrollTop();
            var screenBottom = $(document).scrollTop() + window.innerHeight;
            var $iframe = $(event.target.getIframe());
            var $banner = $iframe.parents('.experience-commerce_assets-mainBanner');
            var videoOffscreen = $iframe.outerHeight() / 4;
            var vidTop = $iframe.offset().top;
            var setStatus = (scrollPos <= vidTop + videoOffscreen && screenBottom >= vidTop + videoOffscreen) ? 'play' : 'pause';
            $banner.addClass('js-has-video');

            if (event.target.getPlayerState() === 1 || event.target.getPlayerState() === 3) {
                $banner.removeClass('js-paused').addClass('js-playing');
            }

            if ((event.target.getPlayerState() === 5 || event.target.getPlayerState() === 2) && setStatus === 'play') {
                // If the video is currently paused or unstarted, and our video has been scrolled into view, play it.
                event.target.playVideo();
                $banner.removeClass('js-paused').addClass('js-playing');
            }

            $(window).on('scroll', function () {
                scrollPos = $(window).scrollTop();
                screenBottom = $(document).scrollTop() + window.innerHeight;
                $iframe = $(event.target.getIframe());
                videoOffscreen = $iframe.outerHeight() / 4;
                vidTop = $iframe.offset().top;

                // play video when over half of it has been scrolled into view, pause when over half is offscreen
                setStatus = (scrollPos <= vidTop + videoOffscreen && screenBottom >= vidTop + videoOffscreen) ? 'play' : 'pause';

                if ((event.target.getPlayerState() === 5 || event.target.getPlayerState() === 2) && setStatus === 'play') {
                    // If the video is currently paused or unstarted, and our video has been scrolled into view, play it.
                    event.target.playVideo();
                    $banner.removeClass('js-paused').addClass('js-playing');
                }

                if (event.target.getPlayerState() === 1 && setStatus === 'pause') {
                    // If the video is currently playing, and our video has been scrolled out of view, pause it.
                    event.target.pauseVideo();
                    $banner.removeClass('js-playing').addClass('js-paused');
                }
            });
        }

        /**
         * Set change events
         * @param {Object} event - contains event data
         */
        function onPlayerStateChange(event) {}
    };

    return videos;
}());

/**
 * Create click events for each video to be opened in a modal
 * @param {Object} $player - jquery object referencing the video player
 */
function createModalClicks($player) {
    if ($player.attr('data-playinmodal') === 'true') {
        var $poster = $player.find('.poster');
        var $heading = $player.find('.image-heading-container');
        var compID = $player.attr('data-componentid');
        var modalID = 'modal-' + compID;
        var $modal = $('#' + modalID);

        $poster.add($heading).on('click', function () {
            $modal.modal('show');
        });
    }
}

/**
 * When Attribute Enabled create modal elements for each applicable video
 */
function createVideoModals() {
    var $players = $('.pd-video-component');

    $players.each(function () {
        var $player = $(this);
        var videoID = $player.attr('data-videoid');
        var compID = $player.attr('data-componentid');

        var modalID = 'modal-' + compID;
        var videoModal = '<div id="' + modalID + '" class="modal fade videoInModal" data-modalcomponentid="' + compID + '" tabindex="-1" role="dialog" aria-labelledby="contentVideoModal">'
            + '  <div class="modal-dialog">'
            + '     <div class="modal-content">'
            + '         <div class="modal-header">'
            + '         <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
            + '      </div>'
            + '      <div class="modal-body">'
            + '          <div data-id="' + videoID + '"  data-type="in-modal" class="video"></div>'
            + '      </div>'
            + '    </div>'
            + '  </div>'
            + '</div>';

        $('body').append(videoModal);

        createModalClicks($player);
    });
}

if ($('.pd-video-component[data-editmode="false"]').length) {
    createVideoModals();
}

var $inPageVids = $('.pd-video-component .video-figure .video');
var $modalVids = $('.videoInModal .video');
var $bgVids = $('.media-banner .video-bg .video');

var videos = new Video();

$inPageVids.add($modalVids).add($bgVids).each(function () {
    videos.add($(this));
});

videos.loadApi();
