'use strict';

var processInclude = require('base/util');
var resourceArticles = require('./components/resourceArticles');
/**
 * Set the content slide Height for the carousel
 */
function setContentSlideHeight() {
    $('.experience-component .carousel').each(function () {
        if ($(this).find('.pd-slider .content-tile').length > 0) {
            $(this).find('.pd-slider').on('init', function (event, slick) {
                $(document).on('lazyloaded', function (e) {
                    if ($(e.target).parents('.content-tile-container').length > 0) {
                        $(this).find('.content-tile-container').matchHeight();
                    }
                });
            });
        }
    });
}
/**
 * adjust height and check for videos etc
 */
function pdSlider() {
    setContentSlideHeight();

    if ($('.pd-slider.slick-initialized').length) {
        $('.pd-slider').slick('unslick');
    }

    //Video slide pause
    if ($('.mainbanner-figure video').length) {
        // Play video inside the current slide
        $('.pd-slider').on('afterChange', function(event, slick, currentSlide) {
            if($('.slick-slide.slick-current').find('video').length !== 0) {
                $('.pd-slider').slick('slickPause');
                $('.pd-slider .slick-current video')[0].play(); 
            }
        });
        $('video').on('ended',function() {
            // console.log('Video Complete');
            $('.pd-slider').slick('slickPlay');
        });
    }

    // Detect if page in in the editor
    if (window.location !== window.parent.location) {
        $(".experience-page").addClass('in-editor');

        $('.experience-component .carousel').each(function () {
            if (!$(this).hasClass('edit-mode')) {
                $(this).find('.pd-slider').slick();
            }
        });
    } else {
        $('.experience-component .carousel').each(function () {
            // var eachSlider = $(this).find('.image-banner');
            // $(this).find('.pd-slider').css('height', eachSlider.height() + 'px');
            $(this).find('.pd-slider').slick();
        });
    }
}
/**
 * get the custom colors
 */
function getCustomColors() {
    let customBtn = document.querySelectorAll('.btn-custom');
    if (customBtn.length) {
        for (let b = 0; b < customBtn.length; b++) {
            customBtn[b].style.setProperty('--bgColor', customBtn[b].dataset.bgcolor);
            customBtn[b].style.setProperty('--textColor', customBtn[b].dataset.textcolor);
            customBtn[b].style.setProperty('--borderColor', customBtn[b].dataset.bordercolor);
            customBtn[b].style.setProperty('--bgColorHover', customBtn[b].dataset.bgcolorhover);
            customBtn[b].style.setProperty('--textColorHover', customBtn[b].dataset.textcolorhover);
            customBtn[b].style.setProperty('--borderColorHover', customBtn[b].dataset.bordercolorhover);
        }
    }
}

/**
 * Create count up functionality of number box
 */
// function numberCountUp() {
//     $('.number-box .number-value').each(function () {
//         var $this = $(this);
//         var str = $this.text();
//         console.log('String', str);
//         var number = str.match(/(\d+,\d+)/g).replace(/,/g, '');
//         console.log('Num', number);

//         var str = numString.replace(/(\d+)/g, '<span class="count-num">$1</span>');

//     });
// }

$(document).ready(function () {
    processInclude(require('pagedesigner/experience/spacer'));
    processInclude(require('appCore/experience/components/video'));
    resourceArticles.pdSingle();
    resourceArticles.compTileBlock();

    // Include Instagram client JS
    processInclude(require('core/components/instagram'));

    $('.pd-text-slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1
    });
    $('.pd-support-slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    // Detect if page in in the editor
    // if (window.location !== window.parent.location) {
    //     $('.experience-page').addClass('in-editor');

    //     $('.experience-component .carousel').each(function (index) {
    //         if (!$(this).hasClass('edit-mode')) {
    //             $(this).find('.pd-slider').slick();
    //         }
    //     });
    // } else {
    //     $('.pd-slider').slick();
    // }
    pdSlider();
    getCustomColors();
});

$(document).on('lazyloaded', function (e) {
    $('.experience-commerce_layouts-featuredBar .featured-item').matchHeight();
});

$(window).smartresize(function () {
    pdSlider();
});
